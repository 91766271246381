import React from 'react';
import './XproBanner.css';
import vladislav from './assets/Vladislav.webp';
import teylor from './assets/Taylor.webp';

export default function XproBanner() {
  return (
    <a href="https://blog.scaletrade.com/10-12-2024" target="__blank">
      <div className="XproBanner">
        <div className="info_Banner">
          {/* <IfxLogo/>  */}
          <p className="main_text">
            <b className="light-blue-gradient">IFX EXPO Dubai 2025</b> book a demo
          </p>
          <img src={vladislav} alt="Vladislav" />
          {/* <img src={arutyun} alt="Arutyun"/> */}
          <img src={teylor} alt="Taylor" />
        </div>
      </div>
    </a>
  );
}
